import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { deserialize } from 'serialize-ts/dist';
import { FRAUD_NOTIFICATION_API, ORDERS_API, CHECK_FRAUD_NOTIFICATION_API } from '@sales-orders-app/modules/orders/constants/api';
import { BuyerDetails, NotifyFraudRequest, Order, OrderDetails } from '@sales-orders-app/modules/orders/models';
import { PaginatedResponse } from '~shared/model';
import { BuyerInfosService } from './tabs-buyer-info.service';

@Injectable({
  providedIn: 'root',
})
export class OrderDetailsService {
  constructor(private httpClient: HttpClient, private buyerInfosService: BuyerInfosService) {}

  loadOrderById(orderId: string): Observable<OrderDetails> {
    return this.httpClient.get<PaginatedResponse<Order>>(`${ORDERS_API}/${orderId}`).pipe(
      map(response => {
        return deserialize(response, OrderDetails);
      }),
      switchMap((orderDetails: OrderDetails) => {
        return this.buyerInfosService.getBuyerById(orderDetails.buyerId).pipe(
          map(buyerInfo => {
            orderDetails.buyerDetails = {
              ...orderDetails.buyerDetails,
              account: buyerInfo.accountNumber,
              cdmId: buyerInfo.cdmId,
            };
            return orderDetails;
          }),
          catchError(error => {
            return of(orderDetails);
          }),
        );
      }),
    );
  }
  notifyFraud(payload: NotifyFraudRequest): Observable<{}> {
    return this.httpClient.post(FRAUD_NOTIFICATION_API, payload);
  }
  checkNotifyFraud(orderNumber: string): Observable<string[]> {
    return this.httpClient.get(`${CHECK_FRAUD_NOTIFICATION_API}/${orderNumber}`).pipe(
      map(response => {
        return response['sellers'];
      })
    );
  }
}
