import { Action } from '@ngrx/store';
import { ErrorResponse } from '~shared/model/error-response.model';

export enum CheckNotifyFraudActionTypes {
  LOAD_CHECK_NOTIFY_FRAUD = '[CheckNotifyFraud] CheckNotifyFraud load',
  LOAD_CHECK_NOTIFY_FRAUD_SUCCESS = '[CheckNotifyFraud] CheckNotifyFraud load success',
  LOAD_CHECK_NOTIFY_FRAUD_FAILURE = '[CheckNotifyFraud] CheckNotifyFraud load failure',
}

export class LoadCheckNotifyFraud implements Action {
  readonly type = CheckNotifyFraudActionTypes.LOAD_CHECK_NOTIFY_FRAUD;

  constructor(public payload: { orderNumber: string }) {}
}

export class LoadCheckNotifyFraudSuccess implements Action {
  readonly type = CheckNotifyFraudActionTypes.LOAD_CHECK_NOTIFY_FRAUD_SUCCESS;

  constructor(public payload: { sellers: string[] }) {}
}

export class LoadCheckNotifyFraudFailure implements Action {
  readonly type = CheckNotifyFraudActionTypes.LOAD_CHECK_NOTIFY_FRAUD_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export type CheckNotifyFraudActions =
  | LoadCheckNotifyFraud
  | LoadCheckNotifyFraudSuccess
  | LoadCheckNotifyFraudFailure;
