import {
  CheckNotifyFraudActionTypes,
  CheckNotifyFraudActions
} from '@sales-orders-app/modules/orders/store/check-notify-fraud/check-notify-fraud.actions';
import {
  CheckNotifyFraudState,
  checkNotifyFraudInitialState
} from '@sales-orders-app/modules/orders/store/check-notify-fraud/check-notify-fraud.state';

export function checkNotifyFraudReducer(state = checkNotifyFraudInitialState, action: CheckNotifyFraudActions): CheckNotifyFraudState {
  switch (action.type) {
    case CheckNotifyFraudActionTypes.LOAD_CHECK_NOTIFY_FRAUD:
      return { ...state, loading: true };
    case CheckNotifyFraudActionTypes.LOAD_CHECK_NOTIFY_FRAUD_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload.sellers,
        error: null,
      };
    case CheckNotifyFraudActionTypes.LOAD_CHECK_NOTIFY_FRAUD_FAILURE:
      return {
        ...state,
        loading: false,
        payload: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
