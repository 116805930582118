import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  LoadCheckNotifyFraud,
  LoadCheckNotifyFraudSuccess,
  LoadCheckNotifyFraudFailure,
  CheckNotifyFraudActionTypes
} from '@sales-orders-app/modules/orders/store/check-notify-fraud/check-notify-fraud.actions';
import { OrderDetailsService } from '@sales-orders-app/modules/orders/services/order-details.service';

@Injectable()
export class CheckNotifyFraudEffects {
  checkNotifyFraud$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckNotifyFraudActionTypes.LOAD_CHECK_NOTIFY_FRAUD),
      map((action: LoadCheckNotifyFraud) => action.payload),
      switchMap(({ orderNumber }) =>
        this.orderDetailsService.checkNotifyFraud(orderNumber).pipe(
          map(sellers => new LoadCheckNotifyFraudSuccess({ sellers })),
          catchError((error) => {
            return of(new LoadCheckNotifyFraudFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private orderDetailsService: OrderDetailsService) {}
}
