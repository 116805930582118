export enum FeatureToggleEnum {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FF_EXTRA_LOGIN_PARAMETERS = 'FF_EXTRA_LOGIN_PARAMETERS',
  FF_OLC_456_NOTIFY_FRAUD = 'FF_OLC_456_NOTIFY_FRAUD',
  FF_TEMP_MPGD_16503_DISPLAY_CALCULATE_REFUND = 'FF_TEMP_MPGD_16503_DISPLAY_CALCULATE_REFUND',
  PIM_TEMP_USE_PDO_63_EXTRACT_SERVICE_PIM_PARSING = 'PIM_TEMP_USE_PDO_63_EXTRACT_SERVICE_PIM_PARSING',
  FF_TEMP_MPGD_25965_VAS_SERVICE = 'FF_TEMP_MPGD_25965_VAS_SERVICE',
  FF_REFUND_REQUESTS_MIGRATION = 'FF_REFUND_REQUESTS_MIGRATION',
  FF_TEMP_GET_ORDERS_V2 = 'FF_TEMP_GET_ORDERS_V2',
  PDS_493_PRODUCT_LIST_VIEW = 'PDS_493_PRODUCT_LIST_VIEW',
  SLACK_CHANNEL_BACKOFFICE_PERMISSIONS = 'SLACK_CHANNEL_BACKOFFICE_PERMISSIONS',
  SLACK_CHANNEL_LINK_BACKOFFICE_PERMISSIONS = 'SLACK_CHANNEL_LINK_BACKOFFICE_PERMISSIONS',
  ENABLE_DEBT_RECONCILIATION_MENU = 'ENABLE_DEBT_RECONCILIATION_MENU',
  FF_PIMPCR_1130_UPLOADS_V2_REFRESH_INTERVAL = 'FF_PIMPCR_1130_UPLOADS_V2_REFRESH_INTERVAL',
  FF_PIMPCR_1047_IS_REPORT_ENABLED = 'FF_PIMPCR_1047_IS_REPORT_ENABLED',
  FF_PIMPCR_1278_IS_BULK_REJECTION_ENABLED = 'FF_PIMPCR_1278_IS_BULK_REJECTION_ENABLED',
  FF_PDS_755_DELETE_KEY_FEATURES = 'FF_PDS_755_DELETE_KEY_FEATURES',
  FF_PIMPCR_1508_ENABLE_LOVS_PAGES_V2 = 'FF_PIMPCR_1508_ENABLE_LOVS_PAGES_V2',
  FF_CCS_BO_RETURN_LABEL_ORDER_LINE = 'FF_CCS_BO_RETURN_LABEL_ORDER_LINE',
  FF_EDIT_ADDRESS = 'FF_EDIT_ADDRESS',
  FF_CCS_MESSAGE_CENTER_BO = 'FF_CCS_MESSAGE_CENTER_BO',

  // Seller Account & Offer
  FF_SOM1782_NEW_BACKOFFICE_KYC_TABLE = 'FF_SOM1782_NEW_BACKOFFICE_KYC_TABLE',
}
