import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { TabsOrderlineState } from '@sales-orders-app/modules/orders/store/tabs-orderline/tabs-orderline.state';
import { State } from '@sales-orders-app/modules/orders/store/state';
import { PaginatedResponse } from '~shared/model';
import { OrderLineDetails } from '@sales-orders-app/modules/orderlines/models/orderline-detail-info.model';

const getModuleFeatureState = createFeatureSelector(ORDERS_FEATURE_NAME);

export const selectTabsOrderlineState = createSelector(getModuleFeatureState, (state: State) => state.tabsOrderline);

export const selectTabsOrderlinePayload = createSelector(
  selectTabsOrderlineState,
  (state: TabsOrderlineState) => state.payload,
);

export const getTabsOrderline = createSelector(
  selectTabsOrderlinePayload,
  (payloadV2: PaginatedResponse<OrderLineDetails>) => payloadV2.items,
);

export const getTabsOrderlineLoading = createSelector(
  selectTabsOrderlineState,
  (state: TabsOrderlineState) => state.loading,
);

export const getTabsOrderlineTotal = createSelector(
  selectTabsOrderlinePayload,
  (payload: PaginatedResponse<OrderLineDetails>) => payload.totalCount,
);

export const getIsReturnLabelGenerating = createSelector(
  selectTabsOrderlineState,
  (state: TabsOrderlineState) => state.isReturnLabelGenerating,
);
