import { HttpClient } from '@angular/common/http';
import { Inject, LOCALE_ID, NgModule } from '@angular/core';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { Observable } from 'rxjs';
import { LocalesEnum } from './constants/locales.enum';
import { LANGUAGE_PICKER } from '~shared/constants';

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const featureEnvPath = (window as any).config.baseHref;
    if (featureEnvPath && featureEnvPath !== '/') {
      const prefix = featureEnvPath.endsWith('/') ? featureEnvPath : `${featureEnvPath}/`;
      return this.http.get(`${prefix}static/assets/i18n/${lang}.json`);
    }
    return this.http.get(`/static/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [TranslateModule],
})
export class L10nModule {
  private languagePickerKey = LANGUAGE_PICKER;

  constructor(
    private translate: TranslateService,
    @Inject(LOCALE_ID) protected localeId: string,
  ) {
    this.translate.addLangs([LocalesEnum.en, LocalesEnum.de]);
    this.translate.setDefaultLang(LocalesEnum.en);

    const currentLocale = JSON.parse(localStorage.getItem(this.languagePickerKey)) || localeId;
    localStorage.setItem(this.languagePickerKey, JSON.stringify(currentLocale));
    this.translate.use(currentLocale);
  }
}
