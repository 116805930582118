import {
  TabsOrderlineInitialState,
  TabsOrderlineState,
} from '@sales-orders-app/modules/orders/store/tabs-orderline/tabs-orderline.state';
import { TabOrderlinesActionsUnion, TabOrderlinesActionTypes } from './tabs-orderline.actions';

export function tabOrderlineReducer(
  state = TabsOrderlineInitialState,
  action: TabOrderlinesActionsUnion,
): TabsOrderlineState {
  switch (action.type) {
    case TabOrderlinesActionTypes.LOAD_TAB_ORDERLINES:
      return { ...state, loading: true };
    case TabOrderlinesActionTypes.LOAD_TAB_ORDERLINES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: { ...action.payload.orderlines },
        error: null,
      };
    case TabOrderlinesActionTypes.LOAD_TAB_ORDERLINES_FAILURE:
      return {
        ...state,
        loading: false,
        payload: {
          items: [],
          totalCount: 0,
        },
        error: action.payload.error,
      };
    case TabOrderlinesActionTypes.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          items: state.payload.items.map(item => {
            if (item.sellerAccountId === action.payload.sellerId) {
              return {
                ...item,
                anonymousEmailLink: action.payload.email,
              };
            }
            return item;
          }),
        },
        error: null,
      };
    case TabOrderlinesActionTypes.CHECK_RETURN_LABEL_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          items: state.payload.items.map(item => {
            const orderline = action.payload.orderlines.find(orderline => orderline.id === item.orderLineId);
            if (orderline) {
              return {
                ...item,
                isEligibleForReturnLabel: true
              };
            } else {
              return {...item};
            }
          })
        }
      };
    case TabOrderlinesActionTypes.RETURN_LABEL_GENERATE:
      return {
        ...state,
        isReturnLabelGenerating: true
      };
    case TabOrderlinesActionTypes.RETURN_LABEL_GENERATE_FAILURE:
    case TabOrderlinesActionTypes.RETURN_LABEL_GENERATE_SUCCESS:
      return {
        ...state,
        isReturnLabelGenerating: false
      };
    default:
      return state;
  }
}
