import faker from 'faker';

const OBJECT_TYPE_LENGTH = 8;

export const objectType = (obj: any): string => Object.prototype.toString.call(obj).slice(OBJECT_TYPE_LENGTH, -1);
export const isUndefined = (param: any): boolean => typeof param === 'undefined';
export const isNull = (param: any): boolean => param === null;
export const isDefined = (param: any): boolean => !isUndefined(param);
export const isNil = (param: any): boolean => isNull(param) || isUndefined(param);
export const isFunction = (param: any): boolean => objectType(param) === 'Function';
export const isNumber = (param: any): boolean => typeof param === 'number';
export const isBoolean = (param: any): boolean => objectType(param) === 'Boolean';
export const isString = (param: any): boolean => typeof param === 'string';
export const isArray = (param: any): boolean => Array.isArray(param);
export const isObject = (param: any): boolean => objectType(param) === 'Object';
export const isDate = (param: any): boolean => objectType(param) === 'Date';
export const isValidDate = (param: any): boolean => !isNaN(param.getTime());

export const getCurrencyHtmlCode = (currency: string) => {
  switch (currency.toLowerCase()) {
    case 'zloty':
      return '&#122;';
    case 'euro':
    default:
      return '&euro;';
  }
};

export const isEmpty = (value: any): boolean => {
  if (!value && value !== 0) {
    return true;
  }

  if (isNumber(value)) {
    return false;
  }

  if (isString(value)) {
    return !value.trim();
  }

  if (isObject(value) && value) {
    return !Object.keys(value).length;
  }

  if (isArray(value)) {
    return !value.length;
  }

  return true;
};

export const clearObject = obj => {
  const result = {};

  Object.keys(obj).forEach(key => {
    if (!isEmpty(obj[key]) || isBoolean(obj[key]) || isNumber(obj[key])) {
      result[key] = obj[key];
    }
  });

  return result;
};

export const clearDeepObject = (obj: unknown): unknown =>
  Object.keys(obj).reduce((acc, key) => {
    if (isObject(obj[key]) && !isEmpty(obj[key])) {
      const clearedObject = clearObject(obj[key]);
      if (!isEmpty(clearedObject)) {
        acc[key] = clearedObject;
      }
    } else if (isBoolean(obj[key]) || !isEmpty(obj[key])) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});

export function parseJSON(jsonString: string, defaultVal = {}) {
  try {
    return JSON.parse(jsonString);
  } catch (err) {
    return defaultVal;
  }
}

export const convertFileToArrayBuffer = (file: File): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = ($readEvent: any) => resolve($readEvent.target.result);

    reader.onerror = () => reject(reader.error);
  });

export function isUrlMatchRoute(url: string, route: string): boolean {
  const regExp = new RegExp(`^\\/${route}\\?`, 'g');

  return url === `/${route}` || regExp.test(url);
}

export function trimObjectValues(values: unknown): unknown {
  return Object.keys(values).reduce((acc, cur) => {
    const value = values[cur];
    if (isString(value)) {
      acc[cur] = value.trim();
    } else if (isObject(value)) {
      acc[cur] = trimObjectValues(value);
    } else if (isArray(value)) {
      acc[cur] = value.map(trimObjectValues);
    } else {
      acc[cur] = value;
    }
    return acc;
  }, {});
}

export const getFakeAmount = currency => ({
  amount: `${faker.random.number()}`,
  currency,
});
