import { environment } from '~env/environment';

export const ORDERS_API = `${environment.svcOrderManagementBaseUrl}/api/v1/order-management/back-office/orders`;
export const ORDERS_API_V2 = `${environment.svcOrderManagementBaseUrl}/api/v2/order-management/back-office/orders`;
export const ORDERS_TAB_PAYMENT_API = `${environment.svcOrderManagementBaseUrl}/api/v1/order-management/back-office/order_payment`;
export const ORDERS_TAB_SELLER_INFO_API = `${environment.svcAftersalesBaseUrl}/api/aftersalesapi/v1/sellers`;
export const ALL_ORDERS_API = `${environment.svcOrderManagementBaseUrl}/api/v1/order-management/back-office/download-orders?`;
export const EXPORT_ORDERS_API = `${environment.svcOrderManagementBaseUrl}/api/v1/order-management/back-office/export-orders?`;
export const ORDERS_EXPORT = `${environment.svcOrderManagementBaseUrl}/api/v1/order-management/back-office/users-export?`;
export const ORDERS_TAB_BUYER_ORDER_INFO_API = `${environment.svcOrderManagementBaseUrl}/api/v1/order-management/back-office/orders/overview`;
export const ORDERS_TAB_BUYER_INFO_API = `${environment.svcUserAccountBaseUrl}/api/internal/backoffice/buyers`;
export const ORDERS_TAB_BUYER_INFO_API_V2 = `${environment.appBuyerAccountBaseUrl}/private/api/v1/buyers-details`;
export const CHECK_EMAILS_API = `${environment.svcAnonymousEmailsBaseUrl}/check-emails`;
export const FRAUD_NOTIFICATION_API = `${environment.svcFraudBaseUrl}/api/v1/fraud/send_notification`;
export const CHECK_FRAUD_NOTIFICATION_API = `${environment.svcFraudBaseUrl}/api/v1/fraud/check_sent_notification`;
export const ORDERLINE_WEE_SERVICE__API = `${environment.svcAnonymousEmailsBaseUrl}/weee/eligibility-status`;
export const ORDERLINE_REPAIR_SERVICE__API = `${environment.svcAftersalesBaseUrl}/api/aftersales/item-repair-eligibility/status`;
export const ORDERLINE_GENERATE_REPAIR_SERVICE__LINK__API = `${environment.svcAftersalesBaseUrl}/api/aftersales/item-repair-eligibility/service-provider-url`;
export const AFTERSALES_API = `${environment.svcAftersalesBaseUrl}/api/aftersalesapi/v1`;
export const AFTERSALES_ADMIN_API = `${environment.svcAftersalesBaseUrl}/api/v1/admin`;
export const ORDER_RETURN_LABEL_API = `${AFTERSALES_API}/sms/return-labels`;
export const ORDER_RETURN_LABEL_AVAILABLE_API = `${AFTERSALES_API}/sms/return-labels/available`;
