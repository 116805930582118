import { ErrorResponse, PaginatedResponse } from '~shared/model';
import { OrderLineDetails } from '@sales-orders-app/modules/orderlines/models/orderline-detail-info.model';

export interface TabsOrderlineState {
  payload: PaginatedResponse<OrderLineDetails>;
  loading: boolean;
  error: ErrorResponse;
  isReturnLabelGenerating: boolean;
}

export const TabsOrderlineInitialState: TabsOrderlineState = {
  payload: {
    items: [],
    totalCount: 0,
  },
  loading: false,
  error: null,
  isReturnLabelGenerating: false
};
