import { environment } from '~env/environment';

export const ORDER_MANAGEMENT_ROOT_API = `${environment.svcOrderManagementBaseUrl}/api/v1/order-management`;
export const ORDER_MANAGEMENT_ROOT_API_V2 = `${environment.svcOrderManagementBaseUrl}/api/v2/order-management`;
export const ORDER_MANAGEMENT_BO_API = `${ORDER_MANAGEMENT_ROOT_API}/back-office`;
export const ORDER_MANAGEMENT_BO_API_V2 = `${ORDER_MANAGEMENT_ROOT_API_V2}/back-office`;
export const ORDERLINES_API = `${ORDER_MANAGEMENT_BO_API}/orderlines`;
export const ORDERLINES_API_V2 = `${ORDER_MANAGEMENT_BO_API_V2}/orderlines`;
export const ORDERLINE_DETAILS_API_V2 = `${ORDER_MANAGEMENT_BO_API_V2}/orderline-details`;
export const ORDERLINES_DOCUMENTS_API = `${ORDERLINES_API}/documents`;
export const ORDERLINES_WARRANTY_API = `${ORDERLINES_API}/api`;
export const AFTER_SALES_V2_ADMIN_API= `${environment.svcAftersalesBaseUrl}/api/v1/admin`;
