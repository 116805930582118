import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NOTIFY_FRAUD_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { State } from '@sales-orders-app/modules/orders/store/state';
import { CheckNotifyFraudState } from '@sales-orders-app/modules/orders/store/check-notify-fraud/check-notify-fraud.state';

const getModuleFeatureState = createFeatureSelector(NOTIFY_FRAUD_FEATURE_NAME);

export const selectCheckNotifyFraudState = createSelector(getModuleFeatureState, (state: State) => state.checkNotifyFraud);

export const selectCheckNotifyFraudPayload = createSelector(
  selectCheckNotifyFraudState,
  (state: CheckNotifyFraudState) => state.payload,
);

export const getCheckNotifyFraud = createSelector(
  selectCheckNotifyFraudPayload,
  (payload: string[]) => payload
);

export const getCheckNotifyFraudError = createSelector(
  selectCheckNotifyFraudState,
  (state: CheckNotifyFraudState) => state.error
);

export const getNotifyFraudLoading = createSelector(
  selectCheckNotifyFraudState,
  (state: CheckNotifyFraudState) => state.loading
);
