import { ErrorResponse } from '~shared/model/error-response.model';

export interface CheckNotifyFraudState {
  payload: string[];
  loading: boolean;
  error: ErrorResponse;
}

export const checkNotifyFraudInitialState: CheckNotifyFraudState = {
  payload: null,
  loading: false,
  error: null,
};
