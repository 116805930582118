import FileSaver from 'file-saver';

export const downloadFile = (file: BlobPart, filename: string, contentType?: string) => {
  FileSaver.saveAs(new Blob([file], { type: contentType }), filename);
};

export const downloadTemplate = (file: BlobPart, filename: string) => {
  const type = 'text/csv;charset=utf-8;';
  downloadFile(file, filename, type);
};
